import styled, {css} from "styled-components";

const Flex = styled.div<{
    row?: boolean;
    gap?: number;
    flexWrap?: boolean;
    spaceBetween?: boolean;
    verticalCenter?: boolean;
    horizontalCenter?: boolean;
    center?: boolean;
    width?: number | string;
    height?: number | string;
    flex?: number;
    spaced?: boolean;
    flexStart?: boolean;
    flexEnd?: boolean;
}>`display: flex;
  ${({row}) => `flex-direction: ${row ? 'row' : 'column'};`};
  ${({flexWrap}) => flexWrap ? `flex-wrap: wrap;` : ''}
  ${({gap}) => gap ? `gap: ${gap}px;` : ''}
  ${({spaceBetween}) => spaceBetween ? `justify-content: space-between;` : ''}
  ${({row, verticalCenter, horizontalCenter}) => {
    if (row) return (verticalCenter ? `align-items: center;` : '') + (horizontalCenter ? `justify-content: center;` : '');
    return (horizontalCenter ? `align-items: center;` : '') + (verticalCenter ? `justify-content: center;` : '');
}}
  ${({center}) => center ? `align-items: center; justify-content: center;` : ''}
  ${({flexStart, row}) => flexStart ? (row ? `justify-content: flex-start;` : `align-items: flex-start;`) : ''}
  ${({flexEnd, row}) => flexEnd ? (row ? `justify-content: flex-end;` : `align-items: flex-end;`) : ''}
  ${({width}) => width ? `width: ${cssSize(width)};` : ''}
  ${({height}) => height ? `height: ${cssSize(height)};` : ''}
  ${({flex}) => flex ? `flex: ${flex};` : ''}
  ${({spaced}) => spaced ? css`
    > * {
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }` : ''}
`;

function cssSize(s: number | string) {
    if (typeof s === "number") return `${s}px`;
    return s;
}

export default Flex;
