import axios from 'axios';

const SERVICE_URL = 'https://obsidian.pocketmath.co.kr';
// const SERVICE_URL = 'https://obsidian-foo.pocketmath.co.kr';
// const SERVICE_URL = "http://localhost:5000";
const API_BASE_URL = SERVICE_URL;
const instance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 30000,
});
let pendingRequest = false;
let pendingSmsVerifyRequest = false;
let pendingCodeVerifyRequest = false;

const toFormParams = (obj) => {
  return Object.keys(obj).map(key => key + '=' + encodeURIComponent(obj[key])).join('&');
};

const postFormHeader = () => {
  return {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }
};


export default {
  submitTrialRequest: (trialRequestForm) => {
    if (!pendingRequest) {
      pendingRequest = true;
      return instance.post(`/open/academy`, toFormParams(trialRequestForm), postFormHeader()).then(r => {
        pendingRequest = false;
        return r;
      }).catch(e => {
        pendingRequest = false;
        throw e;
      });
    }
    return Promise.resolve(false);
  },
  submitCounsellingRequest: (trialRequestForm) => {
    if (!pendingRequest) {
      pendingRequest = true;
      return instance.post(`/open/academy/counselling`, toFormParams(trialRequestForm), postFormHeader()).then(r => {
        pendingRequest = false;
        return r;
      }).catch(e => {
        pendingRequest = false;
        throw e;
      });
    }
    return Promise.resolve(false);
  },
  requestSmsVerification: (trialRequestForm) => {
    if (!pendingSmsVerifyRequest) {
      pendingSmsVerifyRequest = true;
      return instance.get(`/open/smsVerification/request?` + toFormParams(trialRequestForm), postFormHeader()).then(r => {
        pendingSmsVerifyRequest = false;
        return r;
      }).catch(e => {
        pendingSmsVerifyRequest = false;
        throw e;
      });
    }
    return Promise.resolve(false);
  },
  verifySmsVerificationCode: (trialRequestForm) => {
    if (!pendingCodeVerifyRequest) {
      pendingCodeVerifyRequest = true;
      return instance.get(`/open/smsVerification/verify?` + toFormParams(trialRequestForm), postFormHeader()).then(r => {
        pendingCodeVerifyRequest = false;
        return r;
      }).catch(e => {
        pendingCodeVerifyRequest = false;
        throw e;
      });
    }
    return Promise.resolve(false);
  },
  checkValidPhoneNumber: (trialRequestForm) => {
    return instance.get(`/open/academy/isValidPhoneNumber?` + toFormParams(trialRequestForm), postFormHeader()).then(r => {
      return r;
    }).catch(e => {
      throw e;
    });
  }
}
