import React, {FC, useEffect, useState} from "react";
import Flex from "../../common/components/Flex";
import {ContentWrapper, Wrapper} from "../../common/components/common";
import FlowView from "./FlowView";
import MajorFunctionView from "./MajorFunctionView";
import WantedView from "./WantedView";
import FiveReasonView from "./FiveReasonView";
import {getItem} from "../../service/localStorageHelper";
import styled from "styled-components";
import {openFreeTrial} from "../../App";
import queryString from "query-string";
import moment from "moment";
import {useHistory} from "react-router-dom";

const HomeScreen: FC<{}> = () => {
    const [index, setIndex] = useState<number>(1);
    // const [showTrialExamBanner, setShowTrialExamBanner] = useState<boolean>();
    let hasLoginHistory = getItem('has-login-history');
    let url = `https://www.youtube.com/embed/videoseries?list=PLHYqT6YzgbfmaObisHbKA1VX58Glh2Ttk&rel=0&mute=1&modestbranding=1&autoplay=${hasLoginHistory ? 0 : 1}`;

    // const history = useHistory();
    // useEffect(() => {
    //     let queryParams = queryString.parse(history.location.search) as { [key: string]: string };
    //     if ((queryParams["test"] && queryParams["test"] == 'true') || moment().isAfter(moment("2022-11-10 10:00:00")))
    //         setShowTrialExamBanner(true);
    //     // let id = setInterval(() => {
    //     //     setIndex(prevIndex => prevIndex % 2 + 1)
    //     // }, 1000)
    //     // return () => clearInterval(id);
    // }, [])

    return <>
        {/*<Wrapper bgColor={"#00062D"}>*/}
        {/*    <ExamWepWrapper style={{padding: 0, position: "relative", width: '100%'}}>*/}
        {/*        <img src={"assets/imgs/trialExam/exam_web.png"}*/}
        {/*             style={{height: "100%", maxWidth: 1320, width: "100%", objectFit: "contain"}}/>*/}
        {/*        <ExamBtn onClick={() => window.open("https://www.matholic.com/trialExam")}/>*/}
        {/*    </ExamWepWrapper>*/}
        {/*</Wrapper>*/}
        {/*<Wrapper>*/}
        {/*    <ExamMobileWrapper center>*/}
        {/*        <img src={"assets/imgs/trialExam/exam_mobile.png"}*/}
        {/*             style={{height: "100%", maxWidth: 480, width: "100%", objectFit: "contain", marginBottom: 14}}/>*/}
        {/*        <ExamMobileBtn onClick={() => window.open("https://www.matholic.com/trialExam")}/>*/}
        {/*    </ExamMobileWrapper>*/}
        {/*</Wrapper>*/}
        <Wrapper bgColor={"#FFFAFA"}>
            <ContentWrapper style={{padding: 0, position: "relative", width: '100%'}}>
                <BannerWrapper>
                    <img src={"assets/imgs/banner.png"}
                         style={{height: "100%", maxWidth: 1320, width: "100%", objectFit: "contain"}}/>
                    <BannerBtn onClick={() => openFreeTrial()}/>
                    <YoutubeWrapper verticalCenter>
                        <div style={{position: "relative", width: "100%", height: 0, paddingBottom: "56.25%"}}>
                            <iframe id="ytplayer"
                                    src={url}
                                    frameBorder="0"
                                    style={{position: 'absolute', width: '100%', height: '100%'}}/>
                        </div>
                    </YoutubeWrapper>
                </BannerWrapper>
            </ContentWrapper>
        </Wrapper>

        {/*{*/}
        {/*    // showTrialExamBanner &&*/}
        {/*    <>*/}
        {/*        <FixBanner center row onClick={() => window.open("https://parking.matholic.com/trialExam")}>*/}
        {/*            <img src={`assets/imgs/trialExam/202310/line_banner.jpg`} style={{height: "100%", maxWidth: 1320, width: "100%", objectFit: "contain"}}/>*/}
        {/*        </FixBanner>*/}

        {/*        <FixBannerMobile center row onClick={() => window.open("https://parking.matholic.com/trialExam")}>*/}
        {/*            <img src={`assets/imgs/trialExam/202310/mobile_line_banner.jpg`} style={{height: "100%", maxWidth: 1320, width: "100%", objectFit: "contain"}}/>*/}
        {/*        </FixBannerMobile>*/}
        {/*    </>*/}
        {/*}*/}

        <Wrapper bgColor={"#FFFFFF"}>
            <WantedView/>
        </Wrapper>

        <Wrapper bgColor={"#F4F7FC"}>
            <FlowView/>
        </Wrapper>

        <Wrapper bgColor={"#E6EBF4"}>
            <MajorFunctionView/>
        </Wrapper>

        <Wrapper bgColor={"#F4F7FC"}>
            <FiveReasonView/>
        </Wrapper>
    </>
}

const FixBanner = styled(Flex)`
  width: 100%;
  position: sticky;
  top: 0;
  cursor: pointer;
  z-index: 10031;
  background: linear-gradient(90deg, #FCF8F5 43.87%, #8C2B22 61.33%);
  //background: linear-gradient(to left, #58B2E6, #2B3049 30%, #EFEFEF 70%);
  @media (max-width: 480px) {
    display: none;
  }
`

const FixBannerMobile = styled(Flex)`
  width: 100%;
  position: sticky;
  top: 0;
  cursor: pointer;
  z-index: 10031;
  @media (min-width: 480px) {
    display: none;
  }
`

const ExamWepWrapper = styled(ContentWrapper)`
  @media (max-width: 480px) {
    display: none;
  }
`

const ExamMobileWrapper = styled(ContentWrapper)`
  display: none;
  @media (max-width: 480px) {
    padding: 0;
    display: block;
    margin-bottom: 24px;
    position: relative;
  }
`

const ExamMobileBtn = styled.div`
  display: none;
  @media (max-width: 480px) {
    display: block;
    position: absolute;
    width: 100%;
    top: 66%;
    height: 14%;
    cursor: pointer;
  }
`

const ExamBtn = styled.div`
  position: absolute;
  width: 50%;
  height: 14%;
  margin-top: 26%;
  cursor: pointer;
  @media (max-width: 480px) {
    display: none;
  }
`

const BannerBtn = styled.div`
  position: absolute;
  width: 27%;
  left: 3%;
  height: 20%;
  margin-top: 24%;
  cursor: pointer;
  @media (max-width: 480px) {
    display: none;
  }
`

const BannerWrapper = styled(Flex)`
  position: relative;
  width: 100%;

  @media (max-width: 480px) {
    padding-bottom: 56.25%;
    height: 0;
  }
`

const YoutubeWrapper = styled(Flex)`
  position: absolute;
  width: 50%;
  height: 100%;
  right: 20px;

  @media (max-width: 480px) {
    width: 100%;
    right: 0px;
  }
`


export default HomeScreen;
