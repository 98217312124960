import React, {FC} from "react";
import Flex from "../../common/components/Flex";
import {ContentWrapper, Gap, Span} from "../../common/components/common";
import styled from "styled-components";

const Fade = require("react-reveal/Fade");

const fiveReasonScript = [
    {title: "초등부터 고3 문•이과까지 탑재", subTitle: "2015 개정교육과정 반영"},
    {title: "교과서별 문제 제공부터 수능기출까지", subTitle: "시중 교재의 모든 유형 포함"},
    {title: "기본에 충실한 수학 프로그램", subTitle: "오답노트부터 진단평가, 분석 보고서까지"},
    {title: "프로그램 설치 없이도 사용 가능", subTitle: "어디서나 PC, 모바일, 태블릿 등 접속"},
    {title: "가맹비 무료 / 약정기간 없음 / 인원제한 없음", subTitle: "가맹비 부담없이 월 12만원으로 사용 가능"},
]

const FiveReasonView: FC = () => {

    return <ContentWrapper style={{paddingBottom: 40}}>
        <Fade bottom>
            <Flex horizontalCenter>
                <Span size={36} color={"#333333"} style={{
                    fontFamily: "MyLotteMedium",
                    letterSpacing: 3, textAlign: "center"
                }}>
                    수학 선생님들이 포켓매쓰를 선택하는</Span>
                <Span size={54} bold color={"#AA6c39"}>
                    <Span size={100} bold color={"#AA6c39"}>5</Span>가지 이유</Span>
            </Flex>

            <Gap height={20}/>

            {fiveReasonScript.map((v, i) =>
                <Flex row gap={16} center style={{marginBottom: 20}} key={i}>
                    <img src={"assets/icons/wreath_left.png"} style={{height: 80}}/>
                    <Flex center>
                        <Span size={24} color={"#AA6c39"} bold>{v.title}</Span>
                        <Span size={20} color={"#333333"}>{v.subTitle}</Span>
                    </Flex>
                    <img src={"assets/icons/wreath_right.png"} style={{height: 80}}/>
                </Flex>
            )}

            <Gap height={40}/>

            <Flex horizontalCenter>
                <Span size={40} color={"#333333"} style={{
                    fontFamily: "MyLotteMedium",
                    letterSpacing: 3, textAlign: "center"
                }}>
                    무료체험을 통해 지금 경험해보세요
                </Span>
            </Flex>

            <Gap height={40}/>

            <Flex row flexWrap center>
                {["가맹비", "약정기간", "인원제한"].map((v, i) =>
                    <ThreeNoCircle key={i} title={v} idx={i}/>
                )}
            </Flex>
        </Fade>

    </ContentWrapper>
}

const ThreeNoCircle: FC<{ title: string, idx: number }> = ({title, idx}) => {
    const colorSet = ["#54B78A", "#F7B13A", "#6C64A8"]
    return <ThreeNoCircleWrapper center color={colorSet[idx]}>
        <img src={`assets/icons/no_${idx + 1}.png`} style={{width: 64, height: 64}}/>
        <Span size={24} style={{lineHeight: 1}}>{title}</Span>
        <Span bold size={64} color={colorSet[idx]} style={{lineHeight: 1}}>NO</Span>
    </ThreeNoCircleWrapper>
}


const ThreeNoCircleWrapper = styled(Flex)<{ color: string }>`
  width: 200px;
  height: 200px;
  border-radius: 100%;
  ${({color}) => color && `border: 10px solid ${color};`}
  opacity: 0.9;
`

export default FiveReasonView;
