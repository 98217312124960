import React, {FC} from "react";
import Flex from "../../common/components/Flex";
import styled from "styled-components";
import {ContentWrapper, Gap, SectorTitle, Span} from "../../common/components/common";

const WantedScript = [
    {title: "콘텐츠가 중요한 학원", script: `-초중고 전과정 콘텐츠 \n-업계 최다문항 DB보유\n-교과서별 문제 유형 제공`},
    {
        title: "평가시스템이 중요한 학원",
        script: `-입학평가부터 단원, 월말, 진단평가 등 출제기능 \n-심층분석보고서, 간편요약보고서, 보고서 출제가이드 \n-보고서 출력 또는 PDF 파일저장, 카카오톡 공유기능`
    },
    {title: "학습관리가 중요한 학원", script: `-클리닉학습(오답,오답유사),소단원평가 \n-체계적인 개별 학습관리를 위한 일일학습 \n-비대면학습을 위한 모든 출력지 PDF 파일 저장`}
]

const WantedView: FC<{ bgColor?: string }> = (props) => {
    return <ContentWrapper>
        <SectorTitle title={"이런 학원에게 필요합니다!"} letterImg={"wanted"}/>

        <Flex flexWrap gap={60} style={{width: "auto"}}>
            <Flex row flexWrap gap={60}>
                {WantedScript.map((v, i) =>
                    <Wanted title={v.title} script={v.script} idx={i}
                            key={v.title}/>
                )}
            </Flex>
        </Flex>

        <Gap height={100}/>

        <RateOfGrowth/>
    </ContentWrapper>
}

const RateOfGrowth: FC = () => {
    return <Flex center style={{
        border: "0px solid lightgrey", maxWidth: 800,
        width: "100%", paddingTop: 20, paddingBottom: 20,
    }}>
        <Span size={26} bold>{"< 포켓매쓰 성장률 >"}</Span>
        <Flex row horizontalCenter width={"100%"}>
            <Flex style={{width: "100%", marginLeft: 20, borderLeft: "4px solid grey"}}>
                <Bar percent={25}>
                    <div className={"hole"}/>
                    <span>2018</span>
                </Bar>
                <Bar percent={50}>
                    <div className={"hole"}/>
                    <span>2019</span>
                </Bar>
                <Bar percent={100} style={{marginBottom: "10px"}}>
                    <div className={"hole"}/>
                    <span>2020</span>
                </Bar>
            </Flex>
            <Flex center style={{
                marginRight: 20,
                height: 100, minWidth: 100,
                borderRadius: "100%",
                backgroundColor: "green"
            }}>
                <Span bold color={"white"}>사용학원</Span>
                <Span bold color={"white"}>전년대비</Span>
                <Span bold color={"white"}>2배 증가</Span>
            </Flex>
        </Flex>
    </Flex>
}

const CircleFrame = styled(Flex)`
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 300px;
  border-radius: 100%;
  border: 4px solid black;
  margin-bottom: 32px;

  img {
    width: 90%;
    height: 90%;
    border: 1px solid black;
    border-radius: 100%;
    padding: 8px
  }
`

const Wanted: FC<{ title: string, script: string, idx: number }> = ({title, script, idx}) => {
    const colorSet = ["#3AAC7D", "#E55738", "#F5A710"]
    return <Flex flex={1} center style={{marginBottom: 20}}>
        <img src={`assets/imgs/wanted_0${idx + 1}.png`} style={{width: 'auto', maxWidth: 350, minWidth: 350}}/>
        <Span size={28} bold color={colorSet[idx]} style={{letterSpacing: 2}}>{title}</Span>
        <Span size={14} style={{whiteSpace: 'pre'}}>{script}</Span>
    </Flex>
}

const Bar = styled(Flex)<{ percent: number }>`
  position: relative;
  justify-content: center;
  align-items: flex-end;
  width: ${({percent}) => `${percent}%`};
  height: 40px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  background-color: ${({percent}) => percent === 100 ? "black" : "lightgray"};
  margin-top: 10px;

  .hole {
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: white;
    margin-right: 20px;
  }

  span {
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    right: -60px;
    color: ${({percent}) => percent === 100 ? "black" : "gray"};;
  }
`


export default WantedView;