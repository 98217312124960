import {FC} from "react";
import Flex from "../../common/components/Flex";
import {Span, Wrapper} from "../../common/components/common";

const CounsellingSuccess: FC = () => {

    return <Wrapper center bgColor={"#B4C8DA"} style={{height:'100vh'}}>
        <Flex center style={{backgroundColor:'white',padding:80}}>
            <Span size={60} bold>무료 체험 신청 완료</Span>
            <p style={{textAlign: "center"}}>
                무료체험 신청이 완료되었습니다.<br/>
                평일 1일 이내 연락 드리겠습니다.<br/>
                포켓매쓰 서비스를 7일간 체험해 볼 수 있습니다.<br/>
                포켓매쓰에 대해 궁금한 것이 있다면, 이메일 또는 전화로 문의해 주세요.<br/>
                (이메일: help@pocketmath.co.kr / 전화: 1588-9034)<br/>
            </p>
        </Flex>
    </Wrapper>
}

export default CounsellingSuccess;