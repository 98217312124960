import React, {FC, Fragment, useEffect, useState} from "react";
import Flex from "../../common/components/Flex";
import {ContentWrapper, Gap, SectorTitle, Span, Wrapper} from "../../common/components/common";
import openTrialRequestService from "../../service/openTrialRequestService";
import styled from "styled-components";
import {getItem, setItem} from '../../service/localStorageHelper';
import {useHistory} from "react-router-dom";
import {Helmet} from "react-helmet";

function eventTracking(type: any) {
    if (typeof window !== "undefined") {
        // @ts-ignore
        if (window.fbq != null) {
            // @ts-ignore
            window.fbq('track', type, {content_name: 'pocket_math_free_trial'});
        }
    }
    // @ts-ignore
    window.ga && window.ga('send', {
        hitType: 'event',
        eventCategory: 'pocket_math_free_trial',
        eventAction: `pocket-${type}`,
        eventLabel: 'Free Trial Request'
    });
}

const pathList = [
    {label: '영업지사', value: "branch office"},
    {label: '인터넷 검색', value: "internet"},
    {label: '인터넷 카페 (다음 카페, 네이버 카페 등', value: "cafe"},
    {label: '블로그', value: "blog"},
    {label: 'SNS (네이버 밴드, 페이스북, 카카오스토리 등)', value: "SNS"},
    {label: '지인 추천 (친구, 다른 원장, 강사 등)', value: "friend"},
    {label: '브로슈어 및 홍보 자료 등', value: "brochure"},
    {label: '기타', value: "other"},
]

const FreeTrialView: FC = () => {

    const history = useHistory();

    const [state, setState] = useState({
        name: "",
        userAcademyName: "",
        email: '',
        phoneNumber: "",
        selectedAd1: "",
        selectedAd2: "",
        selectedAd3: "",
        code: "", //TODO
        other: "",
        verified: false,
        onVerify: false,
        verificationCode: "",
        path: "",
        privacyAgree: "no",
        question: '',
        timer: false,
        submit: false,
    })
    const [addressJson, setAddressJson] = useState({});

    useEffect(() => {
        // @ts-ignore
        let blogLogNo = getBlogLogNo().logNo;
        if (blogLogNo) {
            setItem('blogLogNo', blogLogNo);
        }
        fetchLegalAddress();
        if (getUrlParam().code) {
            setState({...state, code: getUrlParam().code})
        }
    }, [])

    function fetchLegalAddress() {
        fetch('/assets/data/legal_dong_address.json').then(response => {
            return response.json();
        }).then(data => {
            setAddressJson(data);
        }).catch(err => {
            console.log("error", err);
        });
    }

    function getUrlParam() {
        let params: any = {};
        window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str: any, key: any, value: any): any {
            params[key] = value;
        });
        return params;
    }

    function getReferrer() {
        let gclid = getItem('gclid');
        return JSON.stringify({
            referrer: document.referrer,
            query: getUrlParam(),
            gclid: gclid,
            blogLogNo: getItem('blogLogNo') || null
        })
    }

    function getBlogLogNo() {
        let params = {};
        // @ts-ignore
        document.referrer.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
            // @ts-ignore
            params[key] = value;
        });
        return params;
    }

    function handleSubmit() {

        const phoneRegExp = /^01(?:0|1|[6-9])-\d{3,4}-\d{4}$/;
        const emailRegExp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
        if (!state.name || state.name.length < 2) {
            alert('본인의 이름을 입력해 주세요');
            return;
        }
        if (!state.userAcademyName || state.userAcademyName.length <= 2) {
            alert('학원(공부방)이름을 적어주세요');
            return;
        }
        if (!phoneRegExp.test(state.phoneNumber)) {
            alert('무료체험ID를 받으실 휴대전화번호를 입력해 주세요. ex) 010-9999-9999');
            return;
        }
        if (!state.verified) {
            alert('휴대전화번호를 인증해 주세요.');
            return;
        }
        if (!state.selectedAd1) {
            alert('시/도 를 선택해 주세요.');
            return;
        }
        if (!state.selectedAd2) {
            alert('시/군/구 를 선택해 주세요.');
            return;
        }
        if (!state.selectedAd3) {
            alert('읍/면/동 를 선택해 주세요.');
            return;
        }
        if (!state.path) {
            alert('알게된 경로를 선택하주세요');
            return;
        }
        if (state.privacyAgree === 'no') {
            alert('개인정보처리방침에 동의 후 신청해주세요.');
            return;
        }

        setState({...state, submit: true})

        const trialRequestForm = {
            name: state.name,
            userAcademyName: state.userAcademyName,
            email: state.email,
            phoneNumber: state.phoneNumber,
            address: state.selectedAd1 + " " + state.selectedAd2 + " " + state.selectedAd3,
            path: state.path === "other" ? state.other : state.path,
            code: state.code,
            question: state.question,
            referrer: getReferrer()
        };
        let isCounselling = state.code === null || state.code === "";
        let request = isCounselling ? openTrialRequestService.submitCounsellingRequest : openTrialRequestService.submitTrialRequest;
        request(trialRequestForm)
            .then((r: any) => {
                try {
                    if (r.data.length > 0) {
                        let errorMessage = r.data.map((r: any) => r.defaultMessage)
                            .toString()
                            .replace(",", "\n");
                        alert(errorMessage);
                        setState({...state, submit: false, verified: false, onVerify: false})
                    } else {
                        eventTracking('CompleteRegistration');
                        alert('신청되었습니다.\n감사합니다.');
                        if (isCounselling) {
                            history.replace("/counsellingSuccess")
                        } else {
                            history.replace("/success")
                        }
                    }
                } catch (e) {
                    setState({...state, submit: false})
                    console.log(e);
                }
            })
    }

    function handleChange(event: any) {
        setState({...state, [event.target.name]: event.target.value})
    }

    function verifiedPhoneNumber() {
        const phone = state.phoneNumber.split("").reduce((a: any, b: any) => {
            if (b !== "-") a.push(b);
            return a;
        }, []).reduce((a: any, b: any, i: any) => {
            a += b;
            if ([2, 6].indexOf(i) >= 0) a += "-";
            return a;
        }, "");
        if (phone.length !== 13) {
            alert("휴대전화번호를 입력해 주세요.");
            return;
        }
        setState({...state, phoneNumber: phone})

        const phoneRegExp = /^01(?:0|1|[6-9])-\d{3,4}-\d{4}$/;

        if (!phoneRegExp.test(phone)) {
            alert('휴대전화번호를 입력해 주세요. ex) 010-9999-9999');
            return;
        }

        openTrialRequestService.checkValidPhoneNumber({phoneNumber: phone}).then(r => {
            if (r.data) {
                openTrialRequestService.requestSmsVerification({phoneNumber: phone}).then((r: any) => {
                    if (r.data) {
                        if (r.data) {
                            switch (r.data) {
                                case 'WAIT':
                                    setState({
                                        ...state,
                                        phoneNumber: phone,
                                        onVerify: true,
                                        verified: false,
                                        timer: true
                                    })
                                    alert(`인증코드가 ${phone}로 발송되었습니다.\n확인 후 인증 코드를 입력해 주세요.`);
                                    return;
                                case 'REQUEST_LIMIT_OVER':
                                    setState({...state, phoneNumber: phone, onVerify: false, timer: false})
                                    alert("인증코드 발송 신청 횟수 초과하셨습니다.");
                                    return;
                                case 'SMS_SENDING_FAIL':
                                    setState({...state, phoneNumber: phone, onVerify: false, timer: false})
                                    alert("문자발송에 실패했습니다. 휴대전화번호를 확인해주세요.");
                                    return;
                                default:
                                    return;
                            }
                        }
                    }
                }).catch(e => {
                    console.log(e, "실패");
                    alert("인증코드 발송 실패.");
                })
            } else {
                alert("이미 가입된 번호 입니다.")
            }
        })
    }

    function verifySmsVerificationCode() {
        if (state.verificationCode === "") {
            alert("인증코드를 입력해 주세요");
            return;
        }

        openTrialRequestService.verifySmsVerificationCode({
            phoneNumber: state.phoneNumber,
            code: state.verificationCode
        }).then((r: any) => {
            if (r.data) {
                switch (r.data) {
                    case 'WAIT':
                        return;
                    case 'VERIFIED':
                        setState({...state, verified: true, onVerify: false, timer: false})
                        alert("인증되었습니다.");
                        return;
                    case 'FAILED':
                        alert("인증코드가 일치하지 않습니다.");
                        return;
                    case 'VERIFY_LIMIT_OVER':
                        alert("인증 횟수를 초과로 인증코드가 만료되었습니다. 다시 인증 신청해 주세요.");
                        setState({...state, verified: false, onVerify: false, timer: false})
                        return;
                    case 'UNKNOWN_PHONE_NUMBER':
                        alert("휴대전화번호가 다릅니다. 변경된 휴대폰 번호로 다시 인증 신청해 주세요.");
                        setState({...state, verified: false, onVerify: false, timer: false})
                        return;
                }
            } else {
                alert("인증 에러.");
            }
        }).catch(e => {
            console.log(e);
            alert("인증 에러.");
        })
    }


    return <Flex>
        <Helmet>
            <title>{`포켓매쓰 - 무료체험`}</title>
        </Helmet>
        <Wrapper bgColor={"#B89ADC"}>
            <ContentWrapper style={{padding: 0}}>
                <img src={"assets/imgs/freeTrial/enter_title.png"}
                     style={{
                         maxWidth: 1320, width: "100%", objectFit: "contain"
                     }}/>
            </ContentWrapper>
        </Wrapper>
        <Wrapper bgColor={"#F5F5F5"}>
            <img src={"assets/imgs/freeTrial/enter_step.png"}
                 style={{
                     maxWidth: 1320, width: "100%", objectFit: "contain"
                 }}/>
        </Wrapper>

        <Wrapper>
            <FreeTrialWrapper center>
                <Gap height={40}/>
                <SectorTitle title={"무료체험 신청하기"}/>
                <Flex gap={32} style={{width: "100%", maxWidth: 700}}>
                    <Span color={'red'}>* 포켓매쓰 서비스를 7일간 체험해 볼 수 있습니다.</Span>

                    <InputBox name={"name"} value={state.name} label={"이름"} placeholder={"본인의 이름을 입력해 주세요"}
                              handleChange={handleChange}
                              require/>
                    <InputBox name={"userAcademyName"} value={state.userAcademyName} label={"학원(공부방)"}
                              placeholder={"학원(공부방)이름을 적어주세요"}
                              handleChange={handleChange} require/>

                    <Fragment>
                        <Flex row gap={8}>
                            <InputBox name={"phoneNumber"} label={"휴대전화번호"} value={state.phoneNumber}
                                      placeholder={"무료체험ID를 받으실 휴대전화번호를 입력해 주세요. ex) 010-9999-9999"}
                                      handleChange={handleChange} require/>
                            <StyledButton disabled={state.verified || state.onVerify}
                                          onClick={() => verifiedPhoneNumber()}>{state.verified ? "인증완료" : "인증하기"}</StyledButton>
                        </Flex>
                        {state.onVerify && <Flex gap={8} center style={{padding: 20, border: "1px solid lightgrey"}}>
                            <Span>※ 휴대폰에 문자메시지로 전송된 인증번호 6자리를 입력하신 후 확인 버튼을 눌러주세요</Span>
                            <Flex row gap={16}>
                                <input name={"verificationCode"} placeholder={"인증번호"} onChange={handleChange}
                                       pattern={"^\\d{6}$"}
                                       style={{border: '0', borderBottom: '1px solid lightgrey', fontSize: 18}}/>
                                <StyledButton onClick={() => verifySmsVerificationCode()}>인증번호확인</StyledButton>
                            </Flex>
                            {
                                state.timer && <SMSTimer/>
                            }
                        </Flex>}
                    </Fragment>


                    <Flex gap={8}>
                        <Label label={"지역"} require/>
                        <Flex row gap={4}>
                            <StyledSelect value={state.selectedAd1}
                                          onChange={(v) => setState({
                                              ...state,
                                              selectedAd1: v.target.value,
                                              selectedAd2: ""
                                          })}>
                                <option>시/도</option>
                                {
                                    Object.keys(addressJson).map(v => <option key={v}>{v}</option>)
                                }
                            </StyledSelect>
                            <StyledSelect value={state.selectedAd2}
                                          onChange={(v) => setState({
                                              ...state,
                                              selectedAd2: v.target.value,
                                              selectedAd3: ""
                                          })}>
                                <option>시/군/구</option>
                                {
                                    // @ts-ignore
                                    state.selectedAd1 && Object.keys(addressJson[state.selectedAd1]).sort().map(v =>
                                        <option key={v}>{v}</option>)
                                }
                            </StyledSelect>
                            <StyledSelect
                                value={state.selectedAd3}
                                onChange={(v) => setState({...state, selectedAd3: v.target.value})}>
                                <option>읍/면/동</option>
                                {
                                    // @ts-ignore
                                    (state.selectedAd1 && state.selectedAd2) && addressJson[state.selectedAd1][state.selectedAd2].sort().map(v =>
                                        <option key={v}>{v}</option>)
                                }
                            </StyledSelect>
                        </Flex>
                    </Flex>

                    <Flex gap={4}>
                        <Label label={"알게된 경로"} require/>
                        {
                            pathList.map(v =>
                                <Flex key={v.value}>
                                    <label>
                                        <input type={"radio"} value={v.value} name={"path"}
                                               checked={state.path === v.value}
                                               onChange={handleChange}/>
                                        <Span>{v.label}</Span>
                                    </label>
                                </Flex>
                            )
                        }
                        {
                            state.path === "other" &&
                            <input name={"other"} onChange={handleChange} placeholder={"자세히 적어주시면 감사하겠습니다."}
                                   style={{border: '0', borderBottom: '1px solid lightgrey', fontSize: 18}}/>
                        }
                    </Flex>

                    <InputBox name={"code"} label={"지사/추천인 코드"} placeholder={"지사/추천인의 코드를 적어주세요"} value={state.code}
                              handleChange={handleChange} disabled={!!getUrlParam().code}/>
                    <Flex>
                        <Span size={20}>서비스 이용약관 및 <a href={"https://dia.pocketmath.co.kr/service-privacy.html"}
                                                      target={"_blank"}>개인정보처리방침</a>에 동의합니다.
                        </Span>
                        <Flex row>
                            <label>
                                <input type={"radio"} value={"yes"} name={"privacyAgree"}
                                       checked={"yes" === state.privacyAgree} onChange={handleChange}/>
                                <Span>동의함</Span>
                            </label>
                            <label>
                                <input type={"radio"} value={"no"} name={"privacyAgree"}
                                       checked={"no" === state.privacyAgree} onChange={handleChange}/>
                                <Span>동의 하지 않음</Span>
                            </label>
                        </Flex>
                    </Flex>
                    <StyledButton style={{width: '100%', fontSize: 20}} disabled={state.submit}
                                  onClick={handleSubmit}>{state.submit ? "잠시만 기다려주세요..." : "신청하기"}</StyledButton>
                </Flex>
            </FreeTrialWrapper>

        </Wrapper>
    </Flex>
}

const FreeTrialWrapper = styled(Flex)`
  padding: 60px 20px 60px 20px;
  max-width: 1320px;
  width: auto;
  align-items: center;
  @media (min-width: 700px) {
    width: 100%;
  }
`

const SMSTimer: FC = () => {
    const [minutes, setMinutes] = useState(3);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        const countdown = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(countdown);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => clearInterval(countdown);
    }, [minutes, seconds]);

    return <div>
        남은시간 : {minutes}분 {seconds < 10 ? `0${seconds}` : seconds}초
    </div>
}

const Label: FC<{ label: string, require?: boolean }> = ({label, require}) => {
    return <Span size={20}>{label}{require &&
    <Span size={20} color={'red'} style={{whiteSpace: 'pre'}}>{" *"}</Span>}</Span>
}

const InputBox: FC<{ name: string, label: string, value: string, placeholder: string, require?: boolean, handleChange: (event: any) => void, disabled?: boolean }> = (props) => {
    const {name, label, placeholder, require, handleChange, value, disabled} = props;
    return <Flex flex={1} gap={8}>
        <Flex row>
            <Span size={20}>{label}</Span>
            {require && <Span size={20} color={'red'} style={{whiteSpace: 'pre'}}>{" *"}</Span>}
        </Flex>
        <input type={"text"} name={name} onChange={handleChange} autoComplete={"off"}
               placeholder={placeholder} value={value} disabled={disabled}
               style={{border: '0', borderBottom: '1px solid lightgrey', fontSize: 18}}/>
    </Flex>
}

const StyledButton = styled.button`
  background-color: #4285f4;
  color: #FFFFFF;
  border: 0;
  font-weight: bold;
  padding: 10px 10px;
  cursor: pointer;
`

const StyledSelect = styled.select`
  font-size: 18px;
`

export default FreeTrialView;
