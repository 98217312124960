import styled, {css} from "styled-components";
import Flex from "./Flex";
import React, {FC} from "react";

export const Span = styled.span<{ size?: number, color?: string, bold?: boolean }>`
  font-size: ${({size}) => size ? `${size}px` : "16px"};
  font-weight: ${({bold}) => bold ? "bold" : "normal"};
  color: ${({color}) => color ? color : "black"};
  user-select: none;
`

export const Wrapper = styled(Flex)<{ bgColor?: string }>`
  background-color: ${({bgColor}) => bgColor ? bgColor : "#FFFFFF"};
  width: 100%;
  align-items: center;
`

export const ContentWrapper = styled(Flex)`
  padding: 60px 20px 60px 20px;
  max-width: 1320px;
  width: auto;
  align-items: center;
`

export const Gap = styled.div<{ height: number }>`
  width: 100%;
  ${({height}) => height && css`height: ${height}px;`};
`


const Divider = styled.div`
  width: 100%;
  max-width: 1320px;
  height: 1px;
  background-color: #CCCCCC;
  margin: 40px 0px 40px 0px;
`

export const SectorTitle: FC<{ title: string, letterImg?: "wanted" | "flow" }> = ({title, letterImg}) => {
    return <Flex horizontalCenter style={{marginBottom: 40}}>
        {letterImg && <img src={`assets/imgs/letter_${letterImg}.png`} style={{height: 70}}/>}
        <Span size={36} bold>{title}</Span>
    </Flex>
}
