import React, {FC} from "react";
import Flex from "../../common/components/Flex";
import styled from "styled-components";
import {ContentWrapper, SectorTitle, Span} from "../../common/components/common";

const Fade = require("react-reveal/Fade");
const Slide = require("react-reveal/Slide");
const COLOR_SET = ["#EA5738", "#F8A911", "#3BB07C", "#00B7D9", "#3561AB", "#5A54A0"]
const SubIconNames = [
    {key: "bank", name: '문제은행'},
    {key: "diagnosis", name: '진단평가'},
    {key: "calendar", name: '일일학습'},
    {key: "homework", name: '단원과제'},
    {key: "wrong", name: '오답학습'},
    {key: "wrong_similar", name: '오답유사'},
]

const FlowScript = [
    {
        title: "신규학생",
        script: "진단평가를 통해서 입학테스트용 문제지를 과정별로 준비해둡니다. 특히, 입학테스트 평가보고서는 책자의 형태로 제작하여 상담용 샘플로 비치해둡니다.",
        subIcons: ["bank", "diagnosis"]
    },
    {
        title: "수업 전 TEST",
        script: "이전 수업한 내용에 대한 확인학습으로 수업 전 30분 정도 평가를 실시합니다. 평가 후 답안입력 및 채점까지 진행합니다.",
        subIcons: ["bank", "homework"]

    },
    {
        title: "부교재",
        script: "주교재와 연결되는 부교재는 소단원별로 50~100문제를 구성하여 회차별로 진행합니다. 주교재는 수업시간에 선생님과 함께 풀고 부교재는 학생 스스로 문제를 풀 수 있도록 제공합니다.",
        subIcons: ["bank", "calendar"]

    },
    {
        title: "클리닉",
        script: "문제풀이 후 채점과 첨삭이 이루어집니다. 이후 오답학습, 오답유사학습으로 클리닉을 진행합니다.",
        subIcons: ["wrong", "wrong_similar"]

    },
    {
        title: "내신대비",
        script: "내신대비를 위한 교과서 학습을 진행하고, 실전대비용 모의평가를 진단평가로 출제합니다.",
        subIcons: ["bank", "diagnosis"]

    },
    {
        title: "비대면학습",
        script: "원하는 모든 문제지를 PDF 파일로 생성하여 과제, 비대면 수업 자료, 문제풀이 첨삭 등에 자유롭게 활용합니다.",
        subIcons: ["bank", "calendar"]
    },
]

const FlowView: FC<{ bgColor?: string }> = (props) => {

    return <ContentWrapper>
        <SectorTitle title={"포켓매쓰 수업 6단계"} letterImg={"flow"}/>

        <Flex style={{marginBottom: 10}} horizontalCenter>
            <Fade bottom>
                {
                    FlowScript.map((v, i, arr) =>
                        <Flow key={i} idx={i} last={arr.length === i + 1} title={v.title} script={v.script}
                              subIcons={v.subIcons}/>)
                }
            </Fade>
        </Flex>
    </ContentWrapper>
}

const Flow: FC<{ idx: number, last?: boolean, title: string, script: string, subIcons: string[] }> = (props) => {
    const {last, idx, title, script, subIcons} = props;
    const color = COLOR_SET[idx]
    return <Flex row>
        <Flex horizontalCenter style={{position: "relative", marginRight: 40}}>
            <Circle center color={color}>
                <div className={"inner_circle"}>
                    <span>{idx + 1}</span>
                </div>
                <div className={"dashed_line"}>
                    <div className={"little_dot"}/>
                </div>
            </Circle>
            <Line last={last} color={color}/>
        </Flex>
        <Flex>
            <Flex verticalCenter height={76}>
                <span style={{fontSize: 20, fontWeight: "bold", color: color}}>{title}</span>
            </Flex>
            <Flex row flexWrap style={{minHeight: 100}} verticalCenter gap={20}>
                <Flex style={{marginRight: 30}}>
                    <img src={`assets/icons/flow_0${idx + 1}.png`} style={{
                        width: 200,
                        objectFit: 'contain'
                    }}/>
                </Flex>
                <Flex verticalCenter style={{marginRight: 20}}>
                    <Flex style={{borderLeft: `2px solid ${color}`, paddingLeft: 6, minWidth: 200, maxWidth: 460}}>
                        <span>{script}</span>
                    </Flex>
                </Flex>
                <Flex row gap={4}>
                    {subIcons.map(v =>
                        <FlowSubIcon icon={v} key={v}/>
                    )}
                </Flex>
            </Flex>
        </Flex>
    </Flex>
}

const FlowSubIcon: FC<{ icon: string }> = ({icon}) => {

    return <Flex center style={{width: 64, height: 64, border: '1px solid grey', borderRadius: 12}}>
        <img src={`assets/icons/${icon}.png`} style={{width: 36, objectFit: 'cover'}}/>
        <Span size={12} color={"gray"}>{SubIconNames.find(v => v.key === icon)?.name}</Span>
    </Flex>

}

const Circle = styled(Flex)<{ color: string }>`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background-color: white;
  z-index: 2;
  box-shadow: 2px 7px 17px -3px rgba(0, 0, 0, 0.74);

  .inner_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 68px;
    height: 68px;
    border-radius: 100%;
    border: 1px solid #e0e0e0;
    z-index: 3;

    span {
      font-weight: bold;
      font-size: 36px;
      padding-bottom: 4px;
      color: ${({color}) => color};
    }
  }

  .dashed_line {
    position: absolute;
    right: -26px;
    width: 24px;
    height: 1px;
    border-top: ${({color}) => `1px dashed ${color};`};

    .little_dot {
      position: inherit;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: ${({color}) => color};
      right: -1px;
      top: -3.5px;
    }
  }
`

const Line = styled(Flex)<{ last?: boolean, color?: string }>`
  width: 40px;
  position: absolute;
  top: 10px;
  z-index: 1;
  height: 100%;
  background-color: ${({color}) => color ? color : "blue"};
  border-radius: ${({last}) => last && "0px 00px 16px 16px"};
`

export default FlowView;
