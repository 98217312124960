import {FC} from "react";
import './success.scss';

const Success: FC = () => {

    return <div id="success">
        <section className="trial-form-box">
            <h2 className="page-title">무료 체험 신청 완료</h2>
            <div className="sub-title">포켓매쓰 서비스를 7일간 체험할 수 있습니다.</div>
            <div className="desc">
                <ul>
                    <li className="title">
                        <p>■ 아이디 및 비빌번호 확인</p>
                        <ul className="desc">
                            <li>휴대폰으로 임시 아이디와 임시 비밀번호를 보내드렸습니다.</li>
                            <li>입력하신 연락처로 임시 아이디와 비밀번호가 왔는지 확인 후 로그인하여 사용해주세요.</li>
                        </ul>
                    </li>
                    <li className="title">
                        <p>■ 고객 지원</p>
                        <ul className="desc">
                            <li>사용 중 궁금한 것이 있다면, 아래 고객센터로 문의해 주세요.</li>
                            <li>이메일 또는 전화로 문의해 주세요</li>
                            <li>고객센터 이메일: help@pocketmath.co.kr</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <a href="https://dia.pocketmath.co.kr">
                <button type="submit" className="submit-trial">로그인</button>
            </a>
        </section>
    </div>
}

export default Success