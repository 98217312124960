import React, {FC, useEffect, useState} from "react";
import {ContentWrapper, SectorTitle, Span} from "../../common/components/common";
import Flex from "../../common/components/Flex";
import styled, {css, keyframes} from "styled-components";
import {BsSearch} from "react-icons/bs";

const Fade = require("react-reveal/Fade");
const majorFuncScript = [
    {
        label: "진단평가", color: '#3AAF7C',
        subLabel: "예측할 수 없는 신입생에게 언제든지 진단할 수 있는 테스트를 제공할 수 있습니다. 입학과 동시에 학습상황을 파악할 수 있는 개인별 학습 포트폴리오가 만들어집니다.",
        scriptTitle1: "난이도별 다양한 입학테스트 출제",
        scriptContent1: "신입생의 학습수준을 정확하게 파악할 수 있는 다양한 난이도별 진단평가 출제가 가능하며, 객관적인 평가시스템을 통해 재원생과의 수업 레벨조정의 기준이됩니다.",
        scriptTitle2: "입학 상담 자료로 완벽한 학습 포트폴리오 생성",
        scriptContent2: "평가보고서는 학생에 대한 종합적인 분석이 가능합니다. 10장 이상의 진단지와 함께 학습 추천사항도 제시되어 그 학생만의 학습 포트폴리오가 자동으로 만들어지고 입학 상담 자료로 활용도가 높습니다.",
        tag1: "난이도",
        tag2: "#상세분석보고서 #간편요약보고서 #보고서출제가이드",
    },
    {
        label: "문제은행", color: '#E95638',
        subLabel: "세분화된 난이도별 문제를 원하는대로 배합하여 최적의 맞춤형 문제지를 만들어냅니다. 맞춤형으로 제작된 문제지는 우리 학원만의 학습시스템을 구축할 수 있습니다",
        scriptTitle1: "POCEKTMATH COLLECTION (시스템대로)",
        scriptContent1: "빅데이터 AI 기반으로 선별된 4단계 난이도별 문제가 40만 초중고 수학콘텐츠 안에서 학생 수준별 맞춤형 문제지로 최적화되어 출제됩니다.",
        scriptTitle2: "POCKETMATH SELECTION (선택하는대로)",
        scriptContent2: "학생 또는 반 수준별로 문제지의 과정, 범위, 난이도, 답안형태, 문항수, 문제지 이름, 문제지 테마 등을 설정하고 문제 배합을 조정하여 원하는 문제지를 출제합니다.",
        tag1: "#개념 #기본 #실력 #심화 #3회차구성 #중복출제방지",
        tag2: "#난이도정렬 #단원별정렬 #문제추가/삭제 #문제지테마",
    },
    {
        label: "일일학습", color: '#5A539E',
        subLabel: "제각기 수준이 다른반, 학생에 대한 문제출제 고민 없이 맞춤형 매일 학습으로 원하는 기간에 학습관리를 할 수 있습니다.",
        scriptTitle1: "회차별 구성을 통한 공부습관 형성",
        scriptContent1: "개인별 진도에 따라 회차, 난이도를 구성하여 과제, 테스트, 수업 자료 등의 다양한 형태로 활용할 수 있습니다. 원하는 학습 계획에 따라 규칙적인 학습 관리를 하면 더욱 효과적입니다.",
        scriptTitle2: "일일학습을 통한 개인별 추가학습자료",
        scriptContent2: "회차별 출력이 가능하여 교재로 구성하기 용이합니다. 학생별 회차 수업 후 오답유사학습으로 취약 학습 관리를 진행할 수 있고 문제은행으로 추가테스트도 가능합니다.",
        tag1: "#10회~50회 #4단계난이도 #5~20문제 #학생추가기능",
        tag2: "#회차별출력 #교재구성 #오답유사학습 #문제지/노트",
    },
    {
        label: "학습관리", color: '#F7A811',
        subLabel: "직관적이고 사용하기 쉬운 학습관리 시스템을 통해 급변하는 교육환경에서 그 시기에 맞는 간편한 학습관리로 안전하게 대처할 수 있습니다.",
        scriptTitle1: "관리자도 쉽게 전체 학습 상황을 확인",
        scriptContent1: "출제된 문제지 목록에서 학원 모든 반들의 학습 상황을 확인할 수 있어 학생에 대한 기본적인 학습관리가 가능합니다.",
        scriptTitle2: "학생별로 바로 출제가능한 클리닉학습",
        scriptContent2: "학생별로 답안을 저장하게 되면 몇 초안에 개별 오답유사학습이 생성되어 클리닉학습을 진행합니다.",
        scriptTitle3: "선생님이 직관적으로 확인하는 학습결과",
        scriptContent3: "불필요한 초기 세팅없이 출제한 문제지 목록에서 채점 내용과 학습 결과 뿐만 아니라 오답유사학습까지 바로 확인되어 간편하게 학습관리를 합니다.",
        scriptTitle4: "비대면학습을 위한 모든 출력지 PDF 생성",
        scriptContent4: "생성된 PDF 파일을 쌍방향 수업이 가능한 패드, 전자칠판 등에서 첨삭하고 문제풀이 영상을 만들어 비대면 학습 시에도 학습관리 가능합니다.",
        tag1: "#문제지검색 #문제지목록 #채점/결과 #학생추가",
        tag2: "#답안저장 #채점지출력 #오답유사학습 #PDF파일저장",
    }
]
const MajorFunctionView: FC = () => {
    const [selectTab, setSelectTab] = useState(0)
    const [ani, setAni] = useState(true)

    const mainColor = majorFuncScript[selectTab].color
    const currentTab = majorFuncScript[selectTab]

    useEffect(() => {
        setAni(true)
    }, [currentTab])

    return <ContentWrapper style={{paddingBottom: 0}}>
        <SectorTitle title={"주요 기능"}/>
        <Flex row center>
            {
                majorFuncScript.map((value, idx) =>
                    <MajorFuncButton active={idx === selectTab} color={value.color}
                                     key={idx}
                                     onClick={() => {
                                         setSelectTab(idx)
                                         setAni(false)
                                     }}
                    >
                        <Span size={16}
                              color={idx === selectTab ? "#ffffff" : "#7E797B"}>{`0${idx + 1} ${value.label}`}</Span>
                    </MajorFuncButton>
                )
            }
        </Flex>
        <Fade bottom>
            <Box active={ani} style={{width: "auto", padding: "0px 20px"}} gap={32}>
                <Flex row center gap={16} flexWrap style={{minHeight: 360}}>
                    <img src={`assets/icons/major_func_0${selectTab + 1}.png`} style={{width: 240, height: 240}}/>
                    <Flex width={"30%"} style={{minWidth: '300px'}}>
                        <Span size={32} bold color={majorFuncScript[selectTab].color}>
                            {majorFuncScript[selectTab].label}</Span>
                        <Span style={{whiteSpace: 'normal'}}>{currentTab.subLabel}</Span>
                    </Flex>
                    <img src={`assets/imgs/major_func_0${selectTab + 1}.png`}
                         style={{width: "40%", minWidth: '300px', objectFit: "contain"}}/>
                </Flex>
                <Flex row gap={48} flexWrap>
                    <Flex flex={1} style={{minWidth: 300}}>
                        <Contents title={currentTab.scriptTitle1}
                                  content={currentTab.scriptContent1} color={mainColor}/>
                        {
                            (currentTab.scriptTitle3 && currentTab.scriptContent3) &&
                            <Contents title={currentTab.scriptTitle3}
                                      content={currentTab.scriptContent3} color={mainColor}/>
                        }
                        {currentTab.tag1 === "난이도" ?
                            <LevelBox/> :
                            <Hashtag tag={currentTab.tag1} borderColor={mainColor}/>
                        }
                    </Flex>
                    <Flex flex={1} style={{minWidth: 300}}>
                        <Contents title={currentTab.scriptTitle2}
                                  content={currentTab.scriptContent2} color={mainColor}/>
                        {
                            (currentTab.scriptTitle4 && currentTab.scriptContent4) &&
                            <Contents title={currentTab.scriptTitle4}
                                      content={currentTab.scriptContent4} color={mainColor}/>
                        }
                        <Hashtag tag={currentTab.tag2} borderColor={mainColor}/>
                    </Flex>
                </Flex>
            </Box>
        </Fade>
    </ContentWrapper>
}

const Contents: FC<{ title?: string, content?: string, color: string }> = ({title, content, color}) => {
    return <Flex style={{minHeight: 108}}>
        <Span size={22} bold color={color}>{title}</Span>
        <Span>{content}</Span>
    </Flex>
}

const LevelBox = () => {
    return <Flex row
                 style={{border: '2px solid lightgrey', width: '100%', height: 32, borderRadius: 4, marginTop: 16}}>
        {[1, 2, 3, 4, 5, 6, "사용자선택"].map((v, index, arr) =>

            <Flex center flex={1} key={v} style={{
                height: "100%",
                // backgroundColor: index === 1 ? "#C3E2D2" : "transparent",
                borderRight: arr.length === index + 1 ? '0' : '2px solid lightgrey'
            }}>
                <Span size={12}
                    // color={index === 1 ? "#088537" : "black"}
                >{typeof v === "number" ? `${v}단계` : v}</Span>
                {[1, 6].includes(index + 1) &&
                <Span size={10}>{index + 1 === 1 ? "(쉬움)" : "(어려움)"}</Span>}
            </Flex>
        )}
    </Flex>
}

const Hashtag: FC<{ tag: string, borderColor: string }> = ({tag, borderColor}) => {
    return <Flex spaceBetween verticalCenter row
                 style={{border: `2px solid ${borderColor}`, width: 'auto', padding: '4px 10px', marginTop: 16}}>
        <Span bold>{tag}</Span>
        <BsSearch style={{width: 20, height: 20, borderLeft: '1px solid black', paddingLeft: 10}} color={borderColor}/>
    </Flex>
}

const MajorFuncButton = styled.button<{ active?: boolean, color?: string }>`
  border: 0px solid white;
  padding: 20px 20px;
  cursor: pointer;
  background-color: ${({active, color}) => active ? color : "#F1F1F3"};
`

const boxFade = keyframes`
  0% {
    opacity: 0;
    transform: translate(0, 30px);
  }
  50% {
    opacity: 0.5;
    transform: translate(0, 15px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0px);
  }
`

const Box = styled(Flex)<{ active: boolean }>`
  margin-bottom: 60px;
  ${(props) => props.active && css`
    animation: ${boxFade} 0.5s linear alternate;
    animation-fill-mode: forwards;
  `}
`;


export default MajorFunctionView;
